<template>
  <div>
      <v-select
        :items="types"
        label="勤怠タイプを選択"
        item-text="text"
        return-object
        solo
        class="select-type"
        v-model="clock_type"
        @change="changeClockType"
        :disabled="type == '2'"
      ></v-select>

      <div>
        
        <v-dialog v-model="isDialogShow" max-width="290">
          <template v-slot:activator="{ on, test }">
            <v-btn
              elevation="2"
              block
              x-large
              color="amber accent-3"
              v-bind="test"
              v-on="on"
              :disabled="disabledClock"
              @click="submit"
            >
              イン
            </v-btn>
          </template>
          <v-card class="dialog-card d-flex thumb-content">
            <div>
              <v-img
                max-height="150"
                max-width="150"
                class="thumb-img"
                :src="require(`@/assets/images/${clockInImage}`)"
              ></v-img>
            </div>
            <div>
              <p class="text-center mt-3 footer-text">イン！</p>
              <p class="text-center footer-text">{{ this.clockInMessage }}</p>
            </div>
          </v-card>
        </v-dialog>
      </div>

      <div class="text-center mt-5">
        <p @click="goBack()">キャンセル</p>
      </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      type: this.$route.query.type || "",
      types: [
        { value: 0, text: "現場勤務（リ）", disabled: true },
        { value: 1, text: "現場勤務（個）" },
        { value: 2, text: "運転 & 調整 " },
        { value: 3, text: "参加" },
        { value: 4, text: "タスク" },
        { value: 5, text: "その他" },
      ],
      clock_type: null,
      isDialogShow: false,
    };
  },

  computed: {
    ...mapGetters(["task", "user"]),
    disabledClock() {
      return this.clock_type ? false : true;
    },
    clockInMessage() {
      if (this.type == "2") return "安全運転でいきましょう！";
      return "気持ちよく頑張りましょう！";
    },
    clockInImage() {
      if (this.type == "2") return "driving-start.png";
      return "clock_1.png";
    }
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    changeClockType(a) {
      this.clock_type = a;
      this.$emit("update", a);
    },
    initTime() {
      let type = this.type;

      if (type == 0 && this.task.leader_id == this.user.id) {
        this.types = this.types.map((v) => {
          return v.value == 0 ? { ...v, disabled: false } : v;
        });
      }

      let a =
        this.types.filter((elem) => {
          if (elem.value == type) {
            if (elem.disabled == true) return false;
            return elem.text;
          }
        })[0] || null;

      if (a) this.changeClockType(a);
    },
    submit() {
      this.$emit("submit");
    },
  },

  watch: {
    task() {
      this.initTime();
    },
  },
};
</script>

<style scoped>
.dialog-card {
  justify-content: center !important;
  height: 22rem !important;
}
.thumb-content {
  flex-direction: column;
  align-items: center;
}
.select-type {
  text-align: center;
}
</style>
