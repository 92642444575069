<template>
  <ClockMain />
</template>

<script>
import ClockMain from "@/components/clocks/ClockMain";
export default {
  name: "Clock",
  components: { ClockMain }
};
</script>

<style scoped></style>
