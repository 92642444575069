<template>
  <div v-if="!loading" class="mx-auto">
    <v-row>
      <v-col cols="12">
        <ClockInformationCard :loading="loading" :clock-type="clockType" />
      </v-col>
      <v-col cols="12">
        <ClockTimeCard :loading="loading" @update="updateTime" />
      </v-col>
      <v-col cols="12">
        <ClockActionButton
          :loading="loading"
          @update="updateClock"
          @submit="clockIn"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ClockInformationCard from "@/components/layout/clocks/main/ClockInformationCard";
import ClockTimeCard from "@/components/layout/clocks/main/ClockTimeCard";
import ClockActionButton from "@/components/layout/clocks/main/ClockActionButton";
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";
export default {
  name: "ClockMain",
  components: { ClockTimeCard, ClockInformationCard, ClockActionButton },
  data() {
    return {
      // task_id: this.$route.params.task_id,
      loading: false,
      clockType: null,
      formData: {
        task_id: this.$route.params.task_id,
        type_id: null,
        clock_in: dayjs().format("H:mm"),
      },
    };
  },
  created() {
    this.loading = false;
    this.getTaskById();
  },
  methods: {
    async getTaskById() {
      let id = this.formData.task_id;
      await this.$store.dispatch("GET_TASK_BY_ID", {
        id: id,
        data: { page: "clock" },
      });
    },
    updateClock(clockType) {
      this.clockType = clockType;
      this.formData.type_id = clockType.value;
    },
    updateTime(time) {
      this.formData.clock_in = time;
    },
    clockIn() {
      this.$store.dispatch("CLOCK_IN", this.formData).then((result) => {
        this.loading = false;
        if (result.status === 200) {
          this.isDialogShow = true
          setTimeout(() => {
            this.$router.push({
              name: "tasks"
            });
          }, 2000);
        }
      });
    },
  },
  computed: {
    ...mapGetters(["task"]),
  },
};
</script>

<style scoped></style>
